<template>
  <el-form :model="jiesuanForm" :rules="rules" ref="form" label-width="120px" size="small">
    <el-form-item label="结算金额(元)" prop="amount">
      <el-input type="number" step="0.01" placeholder="0要写成0.00" v-model="jiesuanForm.amount"></el-input>
    </el-form-item>
    <el-form-item label="合同结算说明" prop="remark">
      <el-input type="textarea" v-model="jiesuanForm.remark"></el-input>
    </el-form-item>
    <el-form-item label="上传图片：" prop="photos">
      <el-upload
        ref="upload"
        action="http://ln-jz.cn:8000/uploadImg/"
        multiple
        accept="image/png, image/jpeg,image/gif"
        list-type="picture-card"
        :on-remove="handleRemove"
        :file-list="fileList"
        :before-upload="beforeUploadPicture"
        :on-success="uploadSuccess"
        :auto-upload="false"
      >
        <div slot="tip" class="el-upload__tip">只能上传jpg/png/gif文件，且单个文件不超过10M</div>
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
      </el-upload>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    jiesuanForm: Object,
    fileList: Array
  },
  data() {
    return {
      isValidationOk: false,
      file_count: 0,
      rules: {
        amount: [{ required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/ }]
      }
    }
  },
  methods: {
    // 上传图片前调用方法
    beforeUploadPicture(file) {
      if (file.size > 10 * 1024 * 1024) {
        this.$message.error('上传图片不能大于10M')
        return false
      }
    },
    // 上传图片成功
    uploadSuccess(res, file, fileList) {
      this.file_count += 1
      this.fileChange(fileList)
      console.log('uploadfilelist.len:', fileList.length)
      console.log('file_count:', this.file_count)
    },
    // 移除图片
    handleRemove(file, fileList) {
      console.log('RemovefileList', fileList)
      this.fileChange(fileList)
    },
    // 设置photo字段值
    fileChange(fileList) {
      let temp_str = ''
      this.file_count = fileList.length
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          if (i === 0) {
            //列表中的第一个,作为temp_str的第一个元素
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += '/api/' + fileList[i].response.data //本地
                temp_str += fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += fileList[i].url
            }
          } else {
            //不是列表中的第一个,插入到temp_str中
            if (fileList[i].response) {
              //新上传成功的
              console.log('file response:', fileList[i].response)
              if (fileList[i].response.code === 1000) {
                // temp_str += ',' + '/api/' + fileList[i].response.data //本地
                temp_str += ',' + fileList[i].response.data //服务器
              }
            } else {
              //原来已上传的，url已处理成“/api/...."格式
              temp_str += ',' + fileList[i].url
            }
          }
        }
      }
      this.jiesuanForm.photos = temp_str
      console.log('photos:', this.jiesuanForm.photos)
    },
    submitUpload() {
      this.$refs.upload.submit()
    },
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.file_count < 1) {
            console.log('fileList_len:', this.fileList.length)
            this.$message({
              type: 'alert',
              message: '请至少上传1张图片!',
              duration: 2000
            })
            this.isValidationOk = false
          } else {
            console.log('validation ok!')
            this.isValidationOk = true
          }
        } else {
          console.log('Validation error!')
          this.isValidationOk = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['from'].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped></style>
